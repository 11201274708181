import React from "react";
import emailjs from "emailjs-com";
import "./Contact.css";

const Contact = () => {
  const sendEmail = (e) => {
    e.preventDefault();

    // Send email using EmailJS (temporary solution)
    emailjs
      .sendForm(
        "service_leapp", // Replace with your EmailJS service ID
        "template_1bw756p", // Replace with your EmailJS template ID
        e.target,
        "uI0rdjr3KilmVuRF2" // Replace with your EmailJS user ID
      )
      .then(
        (result) => {
          alert("Email sent successfully!");
        },
        (error) => {
          alert("Failed to send email.");
        }
      );

    e.target.reset(); // Reset the form after submission
  };

  return (
    <div className="contact-container">
      <h1 className="contact-title">Contact Us</h1>
      <p>
        We would love to hear from you! Please fill out the form below to get in
        touch with us, or feel free to contact the lab director directly at{" "}
        <a href="mailto:leapp.mailbot@gmail.com">leapp.mailbot@gmail.com</a>.
      </p>

      <form className="contact-form" onSubmit={sendEmail}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Your name"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Your email"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            rows="5"
            placeholder="Your message"
            required
          ></textarea>
        </div>

        <button type="submit" className="submit-button">
          Send Message
        </button>
      </form>

      {/* Add a section for the director's email for direct contact */}
      <div className="direct-contact">
        <h2>Lab Email</h2>
        <p>
          You can also directly email the lab at:{" "}
          <a href="mailto:leapp.mailbot@gmail.com">leapp.mailbot@gmail.com</a>
        </p>
      </div>

      {/* Embed Google Map */}
      <div className="map-container">
        <h2>Our Location</h2>
        <iframe
          title="Lab Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.1060382806554!2d114.17942871491897!3d22.335707485310594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340408edb7741d7d%3A0x34f2b6375d9a9344!2sHo%20Tim%20Building%2C%20CUHK!5e0!3m2!1sen!2shk!4v1639159597343!5m2!1sen!2shk"
          width="600"
          height="450"
          style={{ border: "0", width: "100%", height: "450px" }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
